import React from "react"
import { graphql } from "gatsby"
import ContentPage from "../components/content-page.component"
import { linkFragment } from "../link-resolver"

const HomePage = ({ data }) => {
  const page = data.prismic.contentPage
  if (page) {
    return <ContentPage pageContext={page}/>
  }
  return null
}

HomePage.fragments = [linkFragment]
export default HomePage

export const query = graphql`
    query homePageQuery($lang: String!) {
        prismic {
            contentPage(uid: "home-page", lang: $lang) {
                page_title
                description
                keywords {
                    keyword
                }
                preview_image
                _meta {
                    uid
                    lang
                }
                header_slides {
                    content
                    icon
                    label
                    background_image
                }
                body {
                    ... on PRISMIC_ContentPageBodyPricing_plans {
                        type
                        label
                        fields {
                            price_units
                            plan_price
                            plan_name
                            plan_features
                            link_text
                            link_style
                            link {
                                ...link
                            }
                            is_free_plan
                        }
                        primary {
                            bg_image
                            bg_color
                            title
                            text
                        }
                    }
                    ... on PRISMIC_ContentPageBodyForm {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            form_script
                            form_url
                            text
                        }
                    }
                    ... on PRISMIC_ContentPageBodyFeatute {
                        type
                        label
                        primary {
                            bg_image
                            bg_color
                            text
                            title
                        }
                        fields {
                            image
                            link_style
                            link_text
                            text
                            title
                            link {
                                ...link
                            }
                        }
                    }
                    ... on PRISMIC_ContentPageBodyBlock_with_text_and_image {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            min_height
                            image
                            link_style
                            link_text
                            link {
                                ...link
                            }
                            text
                            title
                        }
                    }
                    ... on PRISMIC_ContentPageBodyText {
                        type
                        label
                        primary {
                            text
                            background_color
                            background_image
                        }
                    }
                    ... on PRISMIC_ContentPageBodyText_with_title {
                        type
                        label
                        fields {
                            text
                        }
                        primary {
                            background_color
                            background_image
                            title
                            link_text
                            text
                            link {
                                ...link
                            }
                        }
                    }
                    ... on PRISMIC_ContentPageBodyImage_with_text {
                        type
                        label
                        primary {
                            background_color
                            background_image
                            text
                        }
                        fields {
                            image
                            text
                        }
                    }
                    ... on PRISMIC_ContentPageBodyTwo_columns {
                        type
                        label
                        fields {
                            border_color
                            html
                            text
                        }
                        primary {
                            background_color
                            background_image
                            text
                        }
                    }
                    ... on PRISMIC_ContentPageBodyMap {
                        type
                        label
                        primary {
                            latitude
                            longitude
                        }
                    }
                    ... on PRISMIC_ContentPageBodyGallery {
                        type
                        label
                        fields {
                            image
                        }
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_ContentPageBodyText_with_title_list {
                        type
                        label
                        fields {
                            text
                            title
                        }
                        primary {
                            text
                        }
                    }
                    ...on PRISMIC_ContentPageBodyVideo {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            title
                            text
                        }
                        fields {
                            video_thumbnail
                            video_url
                        }
                    }
                    ... on PRISMIC_ContentPageBodyContacts {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            title
                            text
                        }
                        fields {
                            description
                            address
                            email
                            fax
                            phone
                        }
                    }
                }
            }
        }
    }
`
